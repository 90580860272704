$(document).ready(function () {
    $(window).on("scroll", function () {
        var scroll = $(window).scrollTop();

        // if (scroll > 10) {
        //     $('.header, .mainMenu').addClass('scroll');
        // } else {
        //     $('.header, .mainMenu').removeClass('scroll');
        // }

        if (scroll > 300) {
            $('.scrollTop').addClass('active');
            $('#menuButton').addClass('fixed').hide();
            $('#beam').addClass('show');
        } else {
            $('.scrollTop').removeClass('active');
            $('#menuButton').removeClass('fixed').show();
            $('#beam').removeClass('show');
            $('.beam__content').removeClass('active');
        }
    });

    $('#bannerScroll').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: $("#whyUs").offset().top
        }, 'slow');
    });

    $('#scrollTop').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 'slow');
    });

    function showMenu() {
        setTimeout(function () {
            $('#mainMenu').scrollTop(0);
        }, 400);
        $('.beam__content').removeClass('active');
        $('#mainMenu').fadeToggle();
        $('html').toggleClass('overflow');

        if ($('#menuButton').hasClass('fixed')) {
            if ($('#menuButton').hasClass('show')) {
                $('#menuButton').fadeIn();
            } else {
                $('#menuButton').fadeOut();
            }

        }
    }

    $('#menuButton').on('click', function (e) {
        e.preventDefault();


        $(this).toggleClass('active show');
        $('#beamMenuButton').toggleClass('active');
        showMenu();

    });

    $('#beamMenuButton').on('click', function (e) {
        e.preventDefault();
        $('#menuButton').toggleClass('active show');
        $(this).toggleClass('active');
        showMenu();
    });

    $('#phone,#message').on('click', function (e) {
        e.preventDefault();

        var id = $(this).attr('id');
        if (!$('#' + id + 'Beam').hasClass('active')) {
            $('.beam__content').removeClass('active');
            $('#' + id + 'Beam').addClass('active');

        } else {
            $('#' + id + 'Beam').removeClass('active');
        }


    });


    $('.component__dropdownButton').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        if ($(this).hasClass('active'))
        {
            $(this).html('Zwiń');
        }else{
            $(this).html('Dowiedz się więcej');
        }
        $(this).closest('.component__dropdown').find('.component__dropdownText').slideToggle();
    })



    function closeMenu() {
        $('.sub-menu.open').removeClass('open').slideUp();
        $('.menu-item-has-children').removeClass('open');

    }


    $('.menu-item-has-children > a').on('click', function(e){
        e.preventDefault();
        if ($(window).width() < 1299) {
            if (!$(this).parent().hasClass('open')) {
                closeMenu();
                $(this).parent().addClass('open').find('.sub-menu').slideDown().addClass('open');
            } else {
                $(this).parent().removeClass('open').find('.sub-menu').slideUp().removeClass('open');
            }

        } else{
            $(this).parent().addClass('open');
            $(this).parent().find('.sub-menu').addClass('open');

            $('.menu-item-has-children > .sub-menu > li:last-child').focusout(function(){
                $(this).parent().removeClass('open');
                $(this).closest('.menu-item-has-children').removeClass('open');
            });

            $('.menu-item-has-children').mouseleave(function(){
                $(this).find('ul.open').removeClass('open');
                $(this).removeClass('open');
            })
        }
    });



});